@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url(./assets/fonts/Inter-Regular.ttf) format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url(./assets/fonts/Inter-Bold.ttf) format("opentype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url(./assets/fonts/Inter-SemiBold.ttf) format("opentype");
  font-style: normal;
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 24px;
}
.fade-enter,
.fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.6s ease opacity;
}
.fade-enter,
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  z-index: 1;
}

/* Utility */
.dashboard-header-offset {
  padding-top: 65px;
}

.print-only {
  visibility: hidden;
  position: absolute;
  left: -3000px;
}

@media print {
  html {
    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
  }

  .dashboard-header-offset {
    padding-top: 0;
  }

  .no-print {
    display: none;
  }

  .print-only {
    visibility: visible;
    position: relative;
    left: auto;
  }
}
